// src/App.js

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// theme
import ThemeProvider from './theme';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import Router from './routes';
// ----------------------------------------------------------------------

export default function App() {
    const { i18n } = useTranslation();
    const lang = localStorage.getItem("language") || "en";

    useEffect(() => {
      i18n.changeLanguage(lang);
    }, [lang, i18n]);

    return (
        <ThemeProvider>
            {/* <ThemeColorPresets> */}
            {/* <RtlLayout> */}
            <NotistackProvider>
                <MotionLazyContainer>
                    {/* <ProgressBarStyle /> */}

                    {/* <Settings /> */}
                    {/* <ScrollToTop /> */}
                    <Router />
                </MotionLazyContainer>
            </NotistackProvider>
            {/* </RtlLayout> */}
            {/* </ThemeColorPresets> */}
        </ThemeProvider>
    );
}
